import React, { useContext } from 'react';
import Particles from "react-tsparticles";
import { graphql, Link, useStaticQuery } from 'gatsby';
import SEO from './SEO';
import particlesOptions from './particles.json';

export interface LayoutOptions {
	title: string,
	description: string,
	lang? :string,
	image?: string,
	children: any
}

export const Layout = ({
	title,
	description,
	lang,
	image,
	children
}: LayoutOptions) => {
	const data = useStaticQuery(graphql`
		query LayoutQuery {
			site {
				siteMetadata {
					title
					description
				}
			},

			file(relativePath: { eq: "logo.png" }) {
				childImageSharp {
					fixed(width: 800) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`);

	return <div className="w-full flex flex-col">
		<div style={{ position: 'absolute' }}>
			<Particles options={ particlesOptions } />
		</div>

		<header className="w-full fixed z-50 bg-gradient-to-b from-black to-transparent">
			<nav className="h-24 md:h-32 flex justify-center items-center">
				<Link
					to={`/`}
					className=""
				>
					<img className="h-16 md:h-24"
						src={ data.file.childImageSharp.fixed.src }
						alt={ data.site.siteMetadata.title }
					/>
				</Link>
			</nav>
		</header>

		<SEO
			title={ title }
			description={ description }
			lang={ lang }
			image={ image }
		/>

		<main className="container mx-auto flex-grow pt-24 md:pt-32 px-8 sm:px-6 lg:px-8">
			{ children }
		</main>
	</div>;
};
