import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export interface SEOOptions {
	description: string,
	lang?: string,
	title: string,
	image?: string
};

const SEO = ({
	description,
	lang='en',
	title,
	image
}: SEOOptions) => {
	const { site, front } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						siteUrl
						social {
							twitter
						}
					}
				},

				front: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
					frontmatter {
						photo {
							childImageSharp {
								fixed(fit: COVER, height: 1024, width: 1024, cropFocus: ATTENTION, quality: 100) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
		`
	)

	const metaDescription = description ?? site.siteMetadata.description
	const root = site.siteMetadata?.siteUrl;
	const defaultTitle = site.siteMetadata?.title
	const defaultImage = front?.frontmatter?.photo?.childImageSharp?.fixed?.src;

	return <Helmet
		htmlAttributes={{
			lang,
		}}
		title={ title }
		titleTemplate={ defaultTitle ? `%s | ${defaultTitle}` : '%s' }
		meta={[
			{
				name: `description`,
				content: metaDescription,
			},
			{
				property: `og:title`,
				content: title,
			},
			{
				property: `og:description`,
				content: metaDescription,
			},
			{
				property: `og:type`,
				content: `website`,
			},

			{
				name: `twitter:card`,
				content: `summary`,
			},
			{
				name: `twitter:creator`,
				content: site.siteMetadata?.social?.twitter || ``,
			},
			{
				name: `twitter:title`,
				content: title,
			},
			{
				name: `twitter:description`,
				content: metaDescription,
			},

		]}
	/>;
}

export default SEO;
